import { Box, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import "../styles/create-project.css";
import uploadIcon from "../assets/create-project/Group 8.svg"
import cloudIcon from "../assets/create-project/mdi_cloud-upload-outline.svg"
import arrowLeft from "../assets/create-project/arrow-left.svg"
import arrowLeftmob from "../assets/create-project/arrow-leftmob.svg"
import dashImg from "../assets/create-project/Group 8.svg"
import XLicon from "../assets/create-project/excel_svgrepo.com (1).svg"
import tickicon from "../assets/create-project/check.svg"
import crossicon from "../assets/create-project/times.svg"
import cancelicon from "../assets/create-project/carbon_close-filled.svg"
import { useNavigate, useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import { gtag_MeasurementID } from "../config";
import { MenuThreedot } from "../components/menuButton";
ReactGA.initialize(gtag_MeasurementID);

export function CreateProject(props) {
    const api_base_url = props.savedata
    const savedurl = props.api_base_url
    const navigate = useNavigate()
    const fileRef = useRef()
    const [uploadedFile,setUploadedFile] = useState('')
    const [projectName,setProjectName] = useState('')
    const [isCsvSelected,setIsCsvSelected] = useState('')
    const [usertier, setUserTier] = useState('')
    const [isFileUploaded,setIsFileUploaded] = useState(false)
    const [message,setMessage] = useState(false)
    const [fileErr,setFileErr] = useState('')
    const [moberr,setMoberr] = useState('')   // const mobile = useMediaQuery({ maxWidth: 600 })
    const {projectname} = useParams()
    useEffect(()=>{
    setProjectName(projectname)
    },[projectname])
    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && !file.name.endsWith('.csv') && (usertier=="paid plan" && !file.name.endsWith('.xlsx'))) {
            setIsCsvSelected(false)
        }
        else{
            if(usertier=="paid plan"&&file.size > 150 * 1024 * 1024){
            setIsCsvSelected(false)
            setFileErr("File size is restricted to 150MB")
            }
            else if(usertier=="free plan"&&file.size > 5 * 1024 * 1024){
            setIsCsvSelected(false)
            setFileErr("File size is restricted to 5MB")
            }
            else{
            setUploadedFile(file);
            setIsCsvSelected(true)
            setFileErr('')
            }
            }
    };
    useEffect(()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        console.log(user_details)
        async function fetchData() {
            const {data}=await axios.post(savedurl+'dnaviz/payment_info/',{
            "username":user_details.username,
            })
            setUserTier(data.user_tier)
            console.log(usertier)}
            console.log(user_details)
            fetchData()
        if(!user_details){
            navigate("/login")
        }   
    },[])

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleManualFileUpload = (e)=>{
        if(e.target.files[0].name.endsWith('.csv') || (usertier=="paid plan" && e.target.files[0].name.endsWith('.xlsx'))){
            if (usertier=="paid plan"&&e.target.files[0].size > 150 * 1024 * 1024){
            setIsCsvSelected(false)
            setFileErr("File size is restricted to 150MB")
            setMoberr('File size is restricted to 150MB')
            }
            else if (usertier=="free plan"&&e.target.files[0].size > 5 * 1024 * 1024){
            setIsCsvSelected(false)
            setFileErr("File size is restricted to 5MB")
            setMoberr('File size is restricted to 5MB')
            }
            else{
            setUploadedFile(e.target.files[0])
            setIsCsvSelected(true)
            setFileErr('')
            setMoberr('')
            }
            }
        }

    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    },[])
    const handleFileUpload = async()=>{
        const formData = new FormData();
		formData.append("file_url", uploadedFile);
		formData.append("user_details",JSON.stringify({
            "username":JSON.parse(window.sessionStorage.getItem('userdetails')).username,
            "project_name":projectName,
            "chart":"",
            "file_saved":"",
            "comming_file":true
        }))
        setIsFileUploaded(true)
        let res = await axios.post(api_base_url+'fs/check',formData)
        setIsFileUploaded(false)
        if(res.data.isUploaded){
            let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
            user_details['file_name'] = uploadedFile.name
            user_details['project_name'] = projectName
            user_details['chart']=""
            user_details.file_saved=null
            user_details.comming_file=true
            console.log("updated user details---",user_details)
            window.sessionStorage.setItem('userdetails',JSON.stringify(user_details))
            navigate('/chat')
        }
        else if (res.data.message == "File Exists"){
            setFileErr("The uploaded file already exist.")
            setMoberr('The Project Name is already exist')
        }
        else{
            alert(res.data.message)
        }
    }
    const handleKeyPress = (e)=>{
e.preventDefault()
if (e.key === 'Enter') {
handleFileUpload()}
    }
    function logout(){
		// sessionStorage.removeItem("token")
        sessionStorage.removeItem("userdetails")
		navigate("/login")
	}
    const handleCancelSelected = () =>{
        setUploadedFile('')
        setMessage(true)
    }
    return (
        <>
        <Box className="new-project-container">
            {
                isFileUploaded? 
                <Box sx={{display:"flex", justifyContent:"center",alignItems:"center", width:"100%", height:"100vh"}}>
                
                    <CircularProgress size={50}/>
                </Box>
                :
                    <>
                        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <Box className="button-container">
                                <button className="back-button" onClick={() => { navigate(-1) }}> <img src={arrowLeft} className="arrow" alt="arrow-icon" /></button>
                            </Box>
                            <Box className="container-left">
                                <img src={uploadIcon} style={{ width: "100%", maxWidth: "604px", height: "375.51px", flexShrink: "1" }} alt="upload-icon-cover" />
                            </Box>
                        </Box>
                        <Box className="container-right">
                            <Box className="project-details">
                                <Box className="project-instruction">
                                    <Box className="instruction-container">
                                        <Box className="instruction-box">
                                            <Typography sx={{ textAlign: "center", fontFamily: "inter", fontSize: "48px", fontStyle: "normal", fontWeight: "700", lineHeight: "57px", background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", color: "transparent" }}>Create your New Project</Typography>
                                            <Typography sx={{ fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "400", lineHeight: "32px", color: "#FFF", textAlign: "center" }}>Initiate a new project to achieve your goals effectively and efficiently.</Typography>
                                        </Box>

                                    </Box>
                                    <Box className="project-inputs">
                                        <Box className="input-box1">
                                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                                Project Name <Typography component="span" sx={{color:"red"}}>*</Typography>
                                                </Typography>
                                            <input className="project-name" value={projectName} onChange={(e)=>setProjectName(e.target.value)} placeholder="Enter Project Name here"/>
                                        </Box>
                                        {/* <Box className="input-box2">
                                            <input className="upload-file" placeholder="Upload File" />
                                            <button className="browse-button"> <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "#FFF" }}>Browse</Typography> </button>
                                        </Box> */}
                                        <Box className="upload" sx={{cursor:"pointer"}} 
                                            onClick={()=>{fileRef.current&&fileRef.current.click()}}
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                        >
                                            <Box className="upload-drag-drop">
                                                <input ref={fileRef} type="file" accept={usertier === 'paid plan' ? '.csv,.xlsx' : '.csv'} onChange={(e)=>{ handleManualFileUpload(e)}} style={{display:"none"}}/>
                                                <img className="upload-Icon"  src={cloudIcon} alt="upload-icon" />
                                                <Typography sx={{ width:"100%",display:"flex",flexWrap:"wrap", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px", color: "black", textAlign: "center", wordBreak:"break-word",justifyContent:"center" }}>{uploadedFile?`${uploadedFile.name}`:'Choose a file or Drag it here'}</Typography>
                                                {usertier=='free plan' && <Typography sx={{color: "black",fontSize:"12px"}}>Unbeatable experience for files upto 5mb</Typography>}
                                                {usertier=='paid plan' && <Typography sx={{color: "black",fontSize:"12px"}}>Unbeatable experience for files upto 150mb</Typography>}
                                                <Typography sx={{color:"black",fontSize:"12px"}}>{usertier === 'paid plan' ? 'Accepts file format for csv and xlsx' : 'Accepts file format for csv'}</Typography>
                                            </Box>

                                        </Box>
                                        <Typography sx={{color:"red"}}>{fileErr}</Typography>
                                        <button disabled={uploadedFile && projectName ? false : true} onClick={()=>handleFileUpload()} id="file-upload-create-btn" className="createButton" style={{backgroundColor:!projectName || !uploadedFile ?"#475569":"var(--pallate-purple-500, #A855F7)",cursor:!projectName || !uploadedFile ?"not-allowed":"pointer"}}>
                                            <Typography sx={{ fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px", color: "#FFF" }}>Create</Typography> 
                                        </button>

                                    </Box>

                                </Box>

                            </Box>
                        </Box>
                    </>
            }

        </Box>
        <Box className="dashboard-mob">
        {
                isFileUploaded?
                <Box sx={{display:"flex", justifyContent:"center",alignItems:"center", width:"100%", height:"100vh"}}>
                
                    <CircularProgress size={50}/>
                </Box>
                :
                <>
                <div className="threedotBox-cr">
                    <div className="back-dash-cr" onClick={()=>navigate(-1)}>
                    <img src={arrowLeftmob} className="dash-back-icon"/>
                    </div>
                    <MenuThreedot url = {savedurl}/>
                    </div>
                    <img src={dashImg} className="dash-image"/>
                <div className="mob-bottom">
                    <div className="dash-heading-container">
                        <div className="headings-box">
                            <div className="headings-mob">
                            <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"48px",fontStyle:"normal",fontWeight:"700",lineHeight:"57px",background:"linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)",backgroundClip:"text",WebkitTextFillColor:"transparent"}}>Create your New Project</Typography>
                            <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"24px",fontStyle:"normal",fontWeight:"400",lineHeight:"32px",color:"var(--pallate-blue-gray-200, #E2E8F0)"}}>Initiate a new project to achieve your goals effectively and efficiently.</Typography>
                            </div>

                        </div>
                        <div className="dash-button-container">
                            <div className="dash-input-box">
                            <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",color:"var(--light-text-inverse-text, #FFF)"}}>Project Name<span style={{color:"red"}}>*</span></Typography>
                            <div className="dash-input-container">
                                <input className="dash-input-mob" placeholder="Enter Project Name here" value={projectName} onChange={(e)=>setProjectName(e.target.value)}/>
                            </div>
                            <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"12px",fontStyle:"normal",fontWeight:"400",lineHeight:"16px",color:" var(--light-text-danger-text, #EF4444)"}}>{moberr}</Typography>
                                

                            </div>
                        {uploadedFile &&   <div className="file-dash">
                                <div className="files">
                                    <div className="file-icon-box">
                                        <img src={ XLicon} style={{paddingRight:"12px"}} className="icon-xl" alt="xl-image"/>
                                    <Typography sx={{fontFamily:"inter",fontSize:"14px",fontStyle:"normal",fontWeight:"400",lineHeight:"20px",color:"var(--light-text-inverse-text, #FFF)",wordBreak:"break-word"}}>{uploadedFile.name}</Typography>
                                    </div>
                                </div>
                                        <img src={ cancelicon} className="cancel-icon" alt="xl-image" onClick={()=>handleCancelSelected()}/>
                            </div>}
                            <button className="dash-upload" onClick={()=>{fileRef.current&&fileRef.current.click()}}>
                            <input ref={fileRef} type="file" accept=".csv,.xlsx" onChange={(e)=>{ handleManualFileUpload(e)}} style={{display:"none"}}/>
                            <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"14px",fontStyle:"normal",fontWeight:"400",lineHeight:"20px",color:"var(--light-text-inverse-text, #FFF)",display:"flex",alignItems:"center"}}>Upload Data Source</Typography>

                            </button>
                            <button className="dash-create" disabled={uploadedFile && projectName ? false : true}  onClick={()=>handleFileUpload()} style={{marginBottom:"32px",background:!projectName || !uploadedFile ?"#475569":" linear-gradient(90deg, #00C2FF -6.71%, #9746FF 88.41%), #9746FF",cursor:!projectName || !uploadedFile ?"not-allowed":"pointer"}}>
                            <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"14px",fontStyle:"normal",fontWeight:"400",lineHeight:"20px",color:"var(--light-text-inverse-text, #FFF)",display:"flex",alignItems:"center"}}>Create</Typography>
                            </button>
                            {message===true &&<div className="remove-message-box">
                                <div className="remove-tick">
                            <img src={ tickicon} className="remove-tick-icon" alt="xl-image" onClick={()=>setMessage(false)}/>
                                </div>
                            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",color:" var(--pallate-gray-800, #1F2937)",maxWidth:"328px",width:"100%",display:"flex",alignItems:"center",height:"34px"}}>Item removed Successfully</Typography>

                                <div className="remove-cross">
                            <img src={ crossicon} className="remove-cross-icon" alt="xl-image" onClick={()=>setMessage(false)}/>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
           </>
           }
        </Box>

        </>
        
    )
}