import { Box, Button, Typography, CircularProgress, Stack, Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, Modal } from "@mui/material";
import "../styles/Dashboard.css";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import addProject from "../assets/dashboard/add-project.svg"
import fileIcon from "../assets/dashboard/csv-document_svgrepo.com.svg"
import settingsIcon from "../assets/dashboard/Settings.svg"
import downarrowIcon from "../assets/dashboard/Alt Arrow Down.svg"
import optionsIcon from "../assets/dashboard/ellipses-v.svg"
import question from "../assets/dashboard/question.svg"
import searchIcon from "../assets/chat/search.svg";
import AtoZIcon from "../assets/dashboard/sort-alpha-up.svg";
import canvas from "../assets/dashboard/Group.svg"
import backIcon from "../assets/dashboard/ic_twotone-dashboard-customize.svg"
import getstatedIcon from "../assets/create-project/Clip path group.svg"
import threeDotIcon from "../assets/create-project/ellipses-v (1).svg"
import fileImage from "../assets/myprojects/Frame 248.svg"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import { gtag_MeasurementID } from "../config";
import { HighlightOffOutlined } from "@mui/icons-material";
import {motion} from 'framer-motion';
import { MenuThreedot } from "../components/menuButton";
import { Myprojects } from "./myprojects";
import xlsxIcon from "../assets/dashboard/xlsx.png"
ReactGA.initialize(gtag_MeasurementID);

let easeing = [0.6,-0.05,0.01,0.99];
const transition = {duration:1.8,ease:[0.6,0.01,-0.05,0.9]};
const letter = {
    initial:{
      y:400,
    },
    animate:{
      y:0,
      transition:{duration:2, ...transition}
    }
  };

const firstName = {
    initial:{
      y:-30,
    },
    animate:{
      y:0,
      transition:{
        delayChildren:2,
        staggerChildren:1,
        staggerDirection:-1
      }
    }
  }
  const lastName = {
    initial:{
      y:-20,
    },
    animate:{
      y:0,
      transition:{
        delayChildren:0.4,
        staggerChildren:0.04,
        staggerDirection:1
      }
    }
  }
  const header={
    initial:{
      y:-60,
      opacity:0,
      transition:{duration:0.05, ease:easeing}
    },
    animate:{
      y:0,
      opacity:1,
      animation:{
        duration:0.6,
        ease:easeing
      }
    }
  };
export function Dashboard(props) {
    const api_base_url = props.savedata
    const savedurl=props.api_base_url
    const [data, setData] = useState('file')
    const [projects,setProjects] = useState('')
    const [saved_data,setSavedData] =useState([])
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const [Optionsopen,setOptionsopen] = useState({})
    const [isDeleteLoading,setIsDeleteLoading] = useState(false)
    const [myProjects,setMyprojects] = useState(false)
    const [files,setFiles]=useState()
    const [search,setSearch] = useState('')
    const [username,setUsername]=useState('')
    const [renamed_name,setRenamed_name] = useState('')
    const [isRenameModalOpened,setIsRenameModalOpened] = useState(false)
    const [Optionsopen1,setOptionsopen1] = useState(false)
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [sort, setSort] = useState(false)
    const [credits, setCredits] = useState('')
    const [projectfile, setprojectFile] = useState("")
    const [Optionsopenhelp, setOptionsopenHelp] = useState(false)
    const [anchorElHelp, setAnchorElHelp] = useState(null);
    const [renameApiPayload,setRenameApiPayload] = useState({
        username:'',
        fileId:'',
        fileName:'',
        projectName:''
    })
    const [deleteApiPayload,setDeleteApiPayload] = useState({
        username:'',
        fileId:'',
        fileName:'',
        projectName:''
    })
    const [isRenameLoading,setIsRenameLoading] = useState(false)
    const [selectedDeleteFile,setSelectedDeleteFile] = useState("")
    const [isDeleteModalOpened,setIsDeleteModalOpened] = useState(false)
    const [creditsColor,setCreditsColor]=useState('')
    const [ispayment,setIsPayment] = useState(false)
    const [unique_id, setUniqueId] = useState('')

    useEffect(()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        console.log(user_details)
        if(user_details){
            setUsername(user_details.username)
        }
        else{
            navigate("/login")
        }
       
    },[])
    const handleOptionsClick = (event,p_i,f_i) => {
        setAnchorEl(event.currentTarget);
        let dummy_optionOpen = [...Optionsopen]
        dummy_optionOpen[p_i][f_i] = true
        // console.log("in open option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
    };
    const handleOptionsClose = (p_i,f_i) => {
        let dummy_optionOpen = [...Optionsopen]
        dummy_optionOpen[p_i][f_i] = false
        // console.log("in close option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
        // setAnchorEl(null);
    };

    const handleOptionsClickHelp = (event) => {
        // window.history.pushState({}, 'hs_bot', '?hs_bot=true'); window.HubSpotConversations.widget.refresh({ openToNewThread: true });
        setAnchorElHelp(event.currentTarget);
        let dummy_optionOpen = true
        // console.log("in open option",dummy_optionOpen[p_i][f_i])
        setOptionsopenHelp(dummy_optionOpen)
    };
    const handleOptionsCloseHelp = () => {
        let dummy_optionOpen = false
        // console.log("in close option",dummy_optionOpen[p_i][f_i])
        setOptionsopenHelp(dummy_optionOpen)
        // setAnchorEl(null);
    };
    const handleDeleteOption = (project_name,file_name,p_i,f_i)=>{
        let user_details = JSON.parse(window.sessionStorage.getItem("userdetails"))
        console.log(user_details,project_name,file_name,projects[project_name][f_i])
        console.log("saved data",saved_data)
        console.log("files---",files)
        let delete_id = ''
        files.map((file)=>{
            if(file.file_name === file_name && file.project_name === project_name && file.created_by === user_details.username){
                
                console.log(saved_data.map((e)=> e.source_file === file.id && (delete_id = e.source_file)))
            }
        })
        console.log(delete_id)
        setDeleteApiPayload({
            ...deleteApiPayload,
            fileName:file_name,
            projectName:project_name,
            username:user_details.username,
            fileId:delete_id
        })
        setSelectedDeleteFile(file_name)
        handleOptionsClose(p_i,f_i)
        setIsDeleteModalOpened(true)
    }
    const DeleteApi = async()=>{
        try{
            let response = ''
            setIsDeleteLoading(true)
            console.log(deleteApiPayload)
            if(deleteApiPayload.fileId){
                console.log("--------coming inside control api----")
                response = await axios.delete(savedurl+"dnaviz/deletefile/"+deleteApiPayload.fileId)
            }
            else{
                console.log("-----coming for il api---",deleteApiPayload.fileId)
                response = await axios.post(api_base_url+'fs/deleteFile',{
                    "username":deleteApiPayload.username,
                    "projectName":deleteApiPayload.projectName,
                    "fileName":deleteApiPayload.fileName
                })
            }
            if(response.data.isFilesDeleted){
                setSelectedDeleteFile("")
                setDeleteApiPayload({
                    username:'',
                    fileId:'',
                    fileName:'',
                    projectName:''
                })
                setIsDeleteModalOpened(false)
                setIsDeleteLoading(false)
                setprojectFile("")
            }
            else if(response.data.message){
                console.error(response.data.message)
            }
            else{
                console.error(response.error)
            }
        } catch (error) {
            console.error("error at deleting data file---",error)
        }
    }
    useEffect(()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem("userdetails"))
        async function fetchData() {
            const {data}=await axios.post(savedurl+'dnaviz/payment_info/',{
                "username":user_details.username,
                })
            let cred = data.credits_left
            setCredits(cred.toFixed(2))
            if (data.credits_left<50){
            setCreditsColor("var(--light-text-danger-text, #EF4444)")
            setIsPayment(true)
            }
            else{
             setCreditsColor("#00FF00")
             setIsPayment(false)
            }
            }
                 
                fetchData();

        if(user_details){

       
        axios.post(api_base_url+"fs/getFiles",{
            "username":user_details.username
        }).then((res)=>{
            console.log("--------Project Response-----------",res.data)
            setProjects(res.data)
            let dummy_optionOpen = []
            Object.values(res.data).map((ele,ele_i)=>{
                dummy_optionOpen[ele_i] = []
                ele.map((arr_ele)=>dummy_optionOpen[ele_i].push(false))
            })
            setOptionsopen(dummy_optionOpen)
        }).catch((error)=>console.error(error))
        getSaved()
    }

    },[isDeleteLoading,isRenameLoading])
    const getSaved=async ()=>{
        let user_details = JSON.parse(window.sessionStorage.getItem("userdetails"))
        console.log(savedurl+"dnaviz/saved_data/"+user_details.username)
       const {data}=await axios.get(savedurl+"dnaviz/saved_data/"+user_details.username+"/")
       const file=await axios.get(savedurl+"dnaviz/saved_file/"+user_details.username)
       console.log(file.data)
       setFiles(file.data)
       console.log(data)
       setSavedData(data)
    }
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    },[])
    const handleChatOpen = (pname,fname,file_name,index)=>{
        let user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
        user_details.project_name = pname
        console.log(files)
        
            const file_selected=files.filter(item=>item.file_name==fname && pname==item.project_name)
            console.log(file_selected)
            if(file_selected.length>0){
                console.log(file_selected[0].id)
            user_details.file_saved=file_selected[0].id
            }
            else{
                user_details.file_saved=null
            }
            
        
        
        if (fname){
            user_details.file_name = fname
            console.log("in fname",file_name)
            
        }
        else{
            user_details.file_name=file_name[0]
            user_details.file_index =0


        }
        // console.log(user_details)
        // user_details.file_index = index
        user_details.comming_file=true
        user_details.chart=""
        window.sessionStorage.setItem('userdetails',JSON.stringify(user_details))
        navigate('/chat')
    }
    function logout(){
		// sessionStorage.removeItem("token")
        sessionStorage.removeItem("userdetails")
		navigate("/login")
	}
    const openChart=(chart,pname,file_name)=>{
            const file_selected=files.filter(item=>chart.source_file==item.id)
        let user_details=JSON.parse(window.sessionStorage.getItem('userdetails'))
        console.log(chart)
        
        
        user_details.chart=chart
        user_details.project_name=pname
        user_details.file_name=file_selected[0].file_name
        user_details.file_saved=chart.source_file
        user_details.comming_file=false
        console.log(user_details)
        window.sessionStorage.setItem('userdetails',JSON.stringify(user_details))
        navigate('/chat')

    }
    const handleRenameOption = (e,prtn_i,fn_i,project_name,filename)=>{
        e.stopPropagation()
        handleOptionsClose(prtn_i,fn_i)
        setIsRenameModalOpened(true)
        console.log(project_name,filename,username)
        let user_details = JSON.parse(window.sessionStorage.getItem("userdetails"))
        let rename_fileId = ""
        files.filter((item)=>{
            if(item.file_name === filename && item.project_name === project_name && item.created_by === user_details.username){
                rename_fileId = item.id
            }
        })
        setRenameApiPayload({
            ...renameApiPayload,
            projectName:project_name,
            fileName:filename,
            fileId:rename_fileId,
            username:user_details.username
        })
    }
    const RenameApi = async()=>{
        console.log(renameApiPayload)
        let rename = renamed_name
        if(renamed_name){
            if(renamed_name.endsWith(".csv") || renamed_name.endsWith(".xlsx")){
                rename = renamed_name.trim().split(".")[0] + "." + renameApiPayload.fileName.split(".")[1]
            }
            else{
                rename = renamed_name.trim() + "." + renameApiPayload.fileName.split(".")[1]
            }
            let res = await axios.put(savedurl+'dnaviz/renameFile/',{
                ...renameApiPayload,
                "rename":rename
            })
            console.log(res.data)
            if(res.data.isFileRenamed){
                setIsRenameLoading(false)
                setIsRenameModalOpened(false)
                setRenamed_name('')
            }
            else{
                console.log("-----renaming error-------",res.data.error)
                alert("Renaming Failed")
            }
        }
    }
    const handleKeyDown = (e)=>{
        if (e.key === 'Enter') {
            setIsRenameLoading(true)
            RenameApi()
        }
    }
    const handleDeleteCancel = ()=>{
        setDeleteApiPayload({
            username:'',
            fileId:'',
            fileName:'',
            projectName:''
        })
        setIsDeleteModalOpened(false)
    }
    const handleOptionsClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
        let dummy_optionOpen = true
        // console.log("in open option",dummy_optionOpen[p_i][f_i])
        setOptionsopen1(dummy_optionOpen)
    };
    const handleOptionsClose1 = () => {
        let dummy_optionOpen =  false
        // console.log("in close option",dummy_optionOpen[p_i][f_i])
        setOptionsopen1(dummy_optionOpen)
        // setAnchorEl(null);
    };
//     const [searchResult,setSearchResult] = useState('')
//    const handleSearch = (e)=>{
//     e.preventDefault()
//     let res
//     (Object.keys(projects).reverse()).filter((project) => {
                                
//          res = project.toLowerCase().includes(search.toLowerCase())
//     })
//     setSearchResult(res)
//    }
//    console.log(searchResult)
async function redirectURL(){
    //const url = `https://stageaiqpayment.nlugpt.io/payment/?username=${username}`;
    //const url = `https://stage-aiqpayment.arivu-iq.com/payment/?username=${username}`;
    console.log(username)
    const {data}= await axios.get(savedurl+"dnaviz/payment_uid/"+username)
    console.log(data)
    //const url = `http://localhost:3001/payment/?uid=${data.unique_id}`;
    const url = `https://accounts.arivu-iq.com/payment/?uid=${data.unique_id}`;
    window.location.href = url;
    //window.open(`https://stageaiqpayment.nlugpt.io/payment/?username=${username}`);
    //window.open(`http://localhost:3001/payment/?username=${username}`, "_blank");
}
function redirectURLHelp(){
    const url = `https://arivu-iq.com/privacy-policy/`;
    window.open(url,'_blank');
}

    return (
        <> <Box className="dashboard" sx={{border:"none"}}>
            <Box className="get-started-container">
                <Box sx = {{width: "100%", display: "flex", alignItems: "flex-start", justifyContent:projectfile!==""?"space-between":"flex-end", marginRight: "10px" }}>
                  {projectfile!=="" && <div className="back-dash-container" onClick={()=>setprojectFile("")}>
                         <div className="back-dash" >
                                           <img src={backIcon} className="dash-back-icon"/>
                 </div>
                 <Typography sx={{cursor:"pointer", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px",color: "#FFF",marginTop:"41px"}}>Back to Dashboard</Typography>

                    </div>}
                    
               
                <Box sx={{padding:"0"}}>
                
                    <Box  sx={{padding:"0",display:"flex"}} >
                    {/* <Box className="credits_info">
                    <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px", color: "#FFF" ,overflowX:"hidden",background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", color: "transparent"}}>Credits Left:</Typography>
                    <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal",fontWeight: "700", lineHeight: "24px", color: "#FFF" ,overflowX:"hidden",background: creditsColor, backgroundClip: "text", color: "transparent"}}>{credits}</Typography>
                    </Box> */}
                        <Button
                        
                            sx={{ flexShrink:"0",background:"#64748B",color:"#FFF",fontSize:"20px",fontWeight:"800",width:"40px",height:"40px",borderRadius:"50px",marginTop:"38px",marginRight:"32px",cursor:"pointer",border:"2px solid rgb(151, 70, 255)",minWidth:"40px"}}
                            id="file-option-button"
                            aria-controls={Optionsopen1 ? 'file-option-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={Optionsopen1}
                            onClick={(e)=>{e.stopPropagation();handleOptionsClick1(e)}}
                            
                        >
                            {username.toUpperCase()[0]}
                        </Button>
                        <Menu
                            id="file-option-menu"
                            anchorEl={anchorEl1}
                            open={Optionsopen1}
                            // open={true}
                            onClose={()=>{handleOptionsClose1()}}
                            sx={{".MuiMenu-paper":{
                                backgroundColor:"var(--pallate-blue-gray-700, #334155)"
                            }}}
                            MenuListProps={{
                                'aria-labelledby': 'file-option-button',
                                'style':{
                                    color:"var(--light-text-inverse-text, #FFF)",
                                    borderRadius:"4px",
                                    width:"120px",padding:"0"
                                }
                            }}
                        >
                            <MenuItem sx={{padding:"3px",paddingLeft:"15px",display:"flex",alignItems:"center",padding:"8px"}} onClick={logout}>Logout</MenuItem>
                            <MenuItem sx={{padding:"3px",paddingLeft:"15px",display:"flex",alignItems:"center",borderTop:"1px solid grey",padding:"8px"}} onClick={() => {redirectURL()}}>Account</MenuItem>

                        </Menu>
                    
                     {/*<Box><Button sx={{flexShrink:"0",minWidth:"40px",cursor:"pointer",width:"40px",height:"40px",marginTop:"38px",marginRight:"32px",border:"1px solid var(--pallate-blue-gray-500, #64748B)",padding:"8px",borderRadius:"24px"}}
                id="file-help-button"
                aria-controls={Optionsopenhelp ? "file-help-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Optionsopenhelp}
                onClick={(e) => { e.stopPropagation(); handleOptionsClickHelp(e) }}
                >
            <img style={{display:"flex",justifyContent:"center",alignItems:"center",padding:"3.242px 6.255px 3.25px 6.25px"}} src={question}/>
        </Button>
        <Menu
                    id="file-help-menu"
                    anchorEl={anchorElHelp}
                    open={Optionsopenhelp}
                    // open={true}
                    onClose={() => { handleOptionsCloseHelp() }}
                    sx={{
                        ".MuiMenu-paper": {
                            backgroundColor: "var(--pallate-blue-gray-700, #334155)"
                        }
                    }}
                    MenuListProps={{
                        'aria-labelledby': "file-help-button",
                        'style': {
                            color: "var(--light-text-inverse-text, #FFF)",
                            borderRadius: "4px",
                            width: "120px", padding: "0"
                        }
                    }}
                >
                    <MenuItem sx={{ padding: "3px",display: "flex", alignItems: "center", padding: "8px" }} onClick={() => redirectURLHelp()}>Help</MenuItem>

                </Menu>
                </Box>*/}
                 </Box></Box> 
                </Box>
                {projectfile=="" &&  <Box className="get-started-contentbox">
                    <Box className="content-box">
                    <motion.div className="content_wrapper" initial={{opacity:0,scale:0}} animate={{opacity:1, scale:1}} transition={{duration:0.3, ease:easeing}}>
                            <motion.span  variants={firstName} initial="initial" animate="animate" className='first'>
                        <Typography sx={{ fontFamily: "inter", fontSize: "48px", fontStyle: "normal", fontWeight: "700", lineHeight: "57px", background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", color: "transparent" }}>GET STARTED</Typography></motion.span></motion.div>
                        <Typography variant="h1" sx={{ fontFamily: "inter", width: "100%", maxWidth: "445px", textAlign: "center", fontSize: "24px", fontStyle: "normal", fontWeight: "400", lineHeight: "32px", color: " var(--pallate-blue-gray-200, #E2E8F0)" }}>Transform your data into compelling visuals with our intuitive data visualization tool</Typography>
                    </Box>
                    <button id="create-new-viz" className="create-button" onClick={() => { navigate('/new-project') }}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "600", lineHeight: "24px", color: " #FFF" }}>Create New VizData</Typography></button>
                </Box>}

                <Box className="project-container">
                    {/* <Box className="projects">
                        <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "600", lineHeight: "20px", color: "#FFF" }}>Projects</Typography>
                    </Box> */}
                   
                    {projectfile=="" &&  <div className="projects-dash">
                    <Box className="search-container">
                    <Typography variant="h1" sx={{ fontFamily: "inter",color:"#FFF",  textAlign: "center", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>My Projects</Typography>
                        <Box className="search-container-dash">
                            <Box className="search-box">
                                {/* <form className="input-form" onSubmit={(e)=>handleSearch(e)}>

                                <Box className="input-and-button"> */}
                                    <Box className="dashboard-search">
                                <img src={searchIcon} alt="search" />
                                <input onChange={(e)=>setSearch(e.target.value)} className="searchInput"  placeholder="Search" />
                                
                             </Box>
                             {/* <button className="search-button">
                        <Typography sx={{color:" var(--light-text-inverse-text, #FFF)",fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px"}}>Submit</Typography>
                    </button> */}
                                {/* </Box> */}
                                {/* </form> */}
                           <Box className="a-z" onClick={()=>setSort(!sort)}>
                              <img className="a-zIcon" src={AtoZIcon} alt="search" />
                           </Box>
                            </Box>
                           
                    
                        </Box>
                           
                    </Box>
                   
                    <div className="my-projects">
                        {/* <Box className="project-name-container"> */}
                        {projects ? (sort === false ? ((Object.keys(projects)).sort((a, b) => a.localeCompare(b))) : (Object.keys(projects).reverse())).filter((project) => {
                                
                                return search === '' ? projects : project.toLowerCase().includes(search.toLowerCase())
                            }).map((project_name) => (

                                <div className="projrcts-file" style={{cursor:"pointer"}} onClick={() => setprojectFile(project_name)}>
                                    
                                        <img src={fileImage} className="char-image" />
                                   
                                    <div className="chart-details">
                                        <Box sx={{width:"100%",display:"flex",justifyContent:"center"}}>
                                           <Box className="file-name">
                                               <Typography className={project_name.length>12?"text-scroll":""} sx={{ flex: "1 0 0",textAlign:"center", fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "600", lineHeight: "16px", color: "#FFF", maxWidth: "129px", width: "100%",display:"block" }}>{project_name.charAt(0).toUpperCase()+project_name.slice(1)}</Typography>
                                           </Box> 
                                        </Box>
                                    
                                    </div>
                                    {/* <Box sx={{position:"relative",width:"100%"}}>
                                                                        <Box className="viz-options"sx={{right:"0px",bottom:"unset"}} >
                                                                            <Button
                                                                                id="file-option-button"
                                                                                aria-controls={Optionsopen ? 'file-option-menu' : undefined}
                                                                                aria-haspopup="true"
                                                                                aria-expanded={Optionsopen}
                                                                                onClick={(e)=>{e.stopPropagation();handleOptionsClick(e,prtn_i,fn_i)}}
                                                                                
                                                                            >
                                                                                <img className="file-options-icon" src={optionsIcon} alt="new-project" />
                                                                            </Button>
                                                                            <Menu
                                                                                id="file-option-menu"
                                                                                className="file-option-menu"
                                                                                anchorEl={anchorEl}
                                                                                open={Optionsopen[prtn_i][fn_i]}
                                                                                // open={true}
                                                                                onClose={()=>{handleOptionsClose(prtn_i,fn_i)}}
                                                                                sx={{".MuiMenu-paper":{
                                                                                    backgroundColor:"var(--pallate-blue-gray-700, #334155)",
                                                                                }}}
                                                                                MenuListProps={{
                                                                                    'aria-labelledby': 'file-option-button',
                                                                                    'style':{
                                                                                        color:"var(--light-text-inverse-text, #FFF)",
                                                                                        borderRadius:"4px",
                                                                                        width:"138px"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <MenuItem sx={{p:"12px,16px",borderBottom:"1px solid var(--pallate-gray-500, #6B7280)"}}  onClick={()=>handleChatOpen(project_name,filename)}>Edit</MenuItem>
                                                                                <MenuItem sx={{p:"12px,16px",borderBottom:"1px solid var(--pallate-gray-500, #6B7280)"}}  onClick={(e)=>{handleRenameOption(e,prtn_i,fn_i,project_name,filename)}}>Edit</MenuItem>
                                                                                <MenuItem onClick={(e)=>{e.stopPropagation();handleDeleteOption(project_name,filename,prtn_i,fn_i)}}>Remove</MenuItem>
                                                                            </Menu>
                                                                        </Box>
                                                                    </Box> */}


                                </div>)) : null}

                       
                    </div>
                    </div>}
                  {projectfile!=="" && <Box>
                    <Box className="projects">
                        <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "600", lineHeight: "20px", color: "#FFF" }}>My Projects</Typography>
                    </Box>
                    <Box className="search-container" sx={{marginTop:"24px"}}>
                        <Box className="search-container-dash">
                            <Box className="search-box">
                                <Box className="dashboard-search">
                                <img src={searchIcon} alt="search" />
                                <input onChange={(e)=>setSearch(e.target.value)} className="searchInput"  placeholder="Search" />
                             </Box>
                           <Box className="a-z" onClick={()=>setSort(!sort)}>
                              <img className="a-zIcon" src={AtoZIcon} alt="search" />
                           </Box>
                            </Box>
                           
                    {/* <button className="search-button">
                        <Typography sx={{color:" var(--light-text-inverse-text, #FFF)",fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px"}}>Submit</Typography>
                    </button> */}
                        </Box>
                           
                    </Box>
                        {
                            projects ? (sort === false ? (Object.keys(projects).sort((a, b) => a.localeCompare(b))) : (Object.keys(projects).reverse())).filter((project)=>{
                                return search=== ''?projects:project.toLowerCase().includes(search.toLowerCase())
                            }).map((project_name,prtn_i)=>{
                               
                                return( project_name === projectfile &&
                                    <Box className="files-container">
                                        {/* <AccordionSummary>
                                            <Stack direction="row" sx={{width:"100%",justifyContent:"space-between",alignItems:"center"}}>
                                                <Typography sx={{ maxWidth: "570px", width: "100%", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "700", lineHeight: "28px", color: "var(--pallate-blue-gray-200, #E2E8F0)" }}>{project_name}</Typography>
                                                <Box className="project-settings">
                                                    <Button onClick={(e)=>e.stopPropagation()}>
                                                        <img className="project-settting-icons" src={settingsIcon} alt="settings" />
                                                    </Button>
                                                    <img className="project-settting-icons" src={downarrowIcon} alt="down-arrow-icon" />
                                                </Box>
                                            </Stack>
                                        </AccordionSummary> */}
                                        <Typography sx={{color:"#FFF", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "700", lineHeight: "28px",maxWidth:"570px",width:"100%" }}>{project_name.charAt(0).toUpperCase()+project_name.slice(1)}</Typography>
                                        {/* <AccordionDetails>  */}
                                            <Box className="dataSource-container">
                                                <Box className="headings">
                                                    <Box className={data==='file'?"tab-menu":"tab-inactive"} onClick={() => setData("file")}>
                                                        <Typography sx={{color:data==='file'?"var(--pallate-sky-200, #BAE6FD)":"#64748B", cursor:"pointer", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Data Source</Typography>
                                                    </Box>
                                                    <Box className={data==='vizualization'?"tab-menu":"tab-inactive"} onClick={() => setData("vizualization")}>
                                                        <Typography sx={{color:data==='vizualization'?"var(--pallate-sky-200, #BAE6FD)":"#64748B", cursor:"pointer",fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "700", lineHeight: "24px" }}>Data Visualization</Typography>
                                                    </Box>
                                                </Box>
                                                {data === 'file' && myProjects === false && <Box className="data">
                                                    {
                                                        projects[project_name].length>0?<>{projects[project_name].filter((project)=>{
                                                            console.log(project,search)
                                                            return search=== ''?projects[project_name]:project.toLowerCase().includes(search.toLowerCase())
                                                        }).map((filename,fn_i)=>{
                                                            return(
                                                                <Box key={fn_i} className="data-box" sx={{cursor:"pointer"}} onClick={()=>handleChatOpen(project_name,filename)}>
                                                                    <Box className="file-icon">
                                                                        <img src={filename.endsWith(".xlsx")?xlsxIcon:fileIcon} className="csv" alt="file" />
                                                                    </Box>
                                                                    <div className="file-details">
                                                                        <Box sx={{width:"100%",display:"flex",justifyContent:"center"}}>
                                                                           <Box className="file-name" >
                                                                        <Typography className={filename.length>12?"text-scroll":""} sx={{ flex: "1 0 0", textAlign: "center", maxWidth: "129px", width: "100%", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px", color: "#FFF" }}>{filename}</Typography>
                                                                    </Box> 
                                                                        </Box>
                                                                        
                                                                     <Box sx={{position:"relative",width:"100%",marginBottom:"10px",marginTop:"-5px"}}>
                                                                        <Box className="viz-options"sx={{right:"0px",bottom:"unset"}} >
                                                                            <Button
                                                                                id="file-option-button"
                                                                                aria-controls={Optionsopen ? 'file-option-menu' : undefined}
                                                                                aria-haspopup="true"
                                                                                aria-expanded={Optionsopen}
                                                                                onClick={(e)=>{e.stopPropagation();handleOptionsClick(e,prtn_i,fn_i)}}
                                                                                
                                                                            >
                                                                                <img className="file-options-icon" src={optionsIcon} alt="new-project" />
                                                                            </Button>
                                                                            <Menu
                                                                                id="file-option-menu"
                                                                                className="file-option-menu"
                                                                                anchorEl={anchorEl}
                                                                                open={Optionsopen[prtn_i][fn_i]}
                                                                                // open={true}
                                                                                onClose={(e)=>{e.stopPropagation();handleOptionsClose(prtn_i,fn_i)}}
                                                                                sx={{".MuiMenu-paper":{
                                                                                    backgroundColor:"var(--pallate-blue-gray-700, #334155)",
                                                                                }}}
                                                                                MenuListProps={{
                                                                                    'aria-labelledby': 'file-option-button',
                                                                                    'style':{
                                                                                        color:"var(--light-text-inverse-text, #FFF)",
                                                                                        borderRadius:"4px",
                                                                                        width:"138px"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {/* <MenuItem sx={{p:"12px,16px",borderBottom:"1px solid var(--pallate-gray-500, #6B7280)"}}  onClick={()=>handleChatOpen(project_name,filename)}>Edit</MenuItem> */}
                                                                                <MenuItem sx={{p:"12px,16px",borderBottom:"1px solid var(--pallate-gray-500, #6B7280)"}}  onClick={(e)=>{handleRenameOption(e,prtn_i,fn_i,project_name,filename)}}>Edit</MenuItem>
                                                                                <MenuItem onClick={(e)=>{e.stopPropagation();handleDeleteOption(project_name,filename,prtn_i,fn_i)}}>Remove</MenuItem>
                                                                            </Menu>
                                                                        </Box>
                                                                    </Box>
                                                                    </div>
                                                                   
                                                                    
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                    <Box className="save-more" onClick={() => navigate('/new-project/'+projectfile)}>
                                                    <img className="add-project-icon" src={addProject} alt="new-project" />

                                                </Box>
                                                    </>:
                                                     <Box className="save-more" onClick={() => navigate('/new-project/'+projectfile)}>
                                                     <img className="add-project-icon" src={addProject} alt="new-project" />
 
                                                 </Box>}
                                                </Box>}
                                                
                                                {data === 'vizualization' && <Box className="saved-data-container">
                                              
                                                   
                                                 {saved_data.length>0? <> {saved_data.map(chats=>{{
                                                    
                                                   return( chats.project_name==projectfile? 
                                                         
                                                        <Box key={chats.id} className="saved-data" onClick={()=>openChart(chats,project_name)}>
                                                            <Box className="vizualization">
                                                            <img src={canvas} className="canvas-image" alt="canvas" />
                                                            </Box>
                                                            <Box className="vizualization-details">
                                                                <div className="file-name">

                                                                <Typography className={chats.file_name.length>12?"text-scroll":""} sx={{ maxWidth: "147px", width: "100%", fontFamily: "inter", fontSize: "12px", fontStyle: "normal", fontWeight: "600", lineHeight: "16px", color: "#FFF" }}>{chats.file_name}</Typography>
                                                                </div>
                                                                <Typography sx={{ maxWidth: "180px", width: "100%", fontFamily: "inter", fontSize: "10px", fontStyle: "normal", fontWeight: "400", lineHeight: "16px", color: "var(--pallate-blue-gray-300, #CBD5E1)" }}>Created on: { new Date(chats.created_on).getDate() + "-" +( new Date(chats.created_on).getMonth()+1 )+ "-" + new Date(chats.created_on).getFullYear()}</Typography>
                                                            </Box>
    
                                                            
                                                            {/* <Box className="viz-options">
                                                            <img className="options-icon" src={optionsIcon} alt="new-project" />
    
                                                            </Box> */}
    
                                                        </Box>  :
                                                        null)
                                                        
                                                    }})}
                                                    <Box className="save-more" onClick={()=>handleChatOpen(project_name,"",projects[project_name])}>
                                                    <img className="add-project-icon" src={addProject} alt="new-project" />

                                                </Box>
                                                </>:<Box className="save-more" onClick={()=>handleChatOpen(project_name,null,projects[project_name])}>
                                                        <img className="add-project-icon" src={addProject} alt="new-project" />

                                                    </Box> }
                                                    
                                                </Box>}
                                            </Box>
                                        {/* </AccordionDetails> */}
                                    </Box>
                                )
                            })
                            :
                            <Box sx={{textAlign:"center",width:"100%"}}>
                                <CircularProgress/>
                            </Box>
                        }
                    </Box>}
                    {/* <Box className="all-projects">
                        <Button onClick={() => { navigate('/new-project') }}>
                            <img className="add-project-icon" src={addProject} alt="new-project" />
                        </Button>
                    </Box> */}
                </Box>
            </Box>
            {isRenameModalOpened && 
            <Modal
                open={isRenameModalOpened}
                onClose={() => setIsRenameModalOpened(false)}
                closeAfterTransition
                sx={{boxShadow:"0px 0px 148px 0px rgba(0, 0, 0, 0.30)",backdropFilter:"blur(5px)",border:"none",borderRadius:"4px",display:'flex',alignItems:"center",justifyContent:"center"}}
            >
                <Stack gap="10px" sx={{position:"relative",width:"617px",bgcolor:"#0F172A",p:"32px",borderRadius:"4px",textAlign:'center'}}>
                    <HighlightOffOutlined onClick={()=>setIsRenameModalOpened(false)} sx={{cursor:"pointer",color:"#94A3B8",position:"absolute",top:"16px",right:"22px",width:"32px",height:"32px"}}/>
                    <Stack gap="32px">
                        <Stack gap="12px">
                            <Typography sx={{ fontFamily: "inter", fontSize: "32px", fontStyle: "normal", fontWeight: "700", lineHeight: "48px", background: "linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)", backgroundClip: "text", color: "transparent" }}>RENAME</Typography>
                            <Stack gap="24px">
                                <Box sx={{textAlign:"left"}}>
                                    <Typography sx={{fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"24px",color:"#64748B",mb:"8px"}}>Existing DataSource Name</Typography>
                                    <input readOnly disabled value={renameApiPayload.fileName} style={{width:"-webkit-fill-available",padding:"12px",outline:"none",border:"none",borderRadius:"4px",backgroundColor:"var(--light-forms-background, #CBD5E1)",color: "#64748B",fontSize:"16px",lineHeight:"24px",fontWeight:400}}/>
                                </Box>
                                <Box sx={{textAlign:"left"}}>
                                    <Typography sx={{fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"24px",color:"#FFF",mb:"8px"}}>New DataSource Name</Typography>
                                    <input value={renamed_name} onKeyDown={(e)=>handleKeyDown(e)}  onChange={(e)=>{setRenamed_name(e.target.value)}} placeholder="Enter a new name" style={{width:"-webkit-fill-available",padding:"12px",outline:"none",border:"none",borderRadius:"4px",backgroundColor:"#FFFFFF",color: "var(--light-text-secondary-text, #64748B)",fontSize:"16px",lineHeight:"24px",fontWeight:400}}/>
                                </Box>
                            </Stack>
                        </Stack>
                        {isRenameLoading?
                            <Box sx={{width:"inherit",textAlign:"center"}}>
                                <CircularProgress/>
                            </Box>
                        :
                            <Button disableRipple disableTouchRipple disabled={renamed_name.trim() === ''?true:false} variant="contained" sx={{p:"12px 24px",background:"linear-gradient(0deg, #9746FF 0%, #9746FF 100%), #00C2FF"}} onClick={()=>{setIsRenameLoading(true);RenameApi()}}>Save</Button>}
                    </Stack>
                </Stack>
            </Modal>}
            {/* {ispayment && <Modal
                open={ispayment}
                onClose={()=>setIsPayment(false)}
                sx={{backdropFilter:"blur(5px)",display:"flex",justifyContent:"center",alignItems:"center"}}
            >
                <Stack gap="16px" sx={{width:"300px",textAlign:"center",p:"24px",borderRadius:"8px",background:"var(--pallate-blue-gray-100, #F1F5F9)",boxShadow:"0px 0px 36px 0px rgba(0, 0, 0, 0.25)"}}>
                    <Stack gap="8px" sx={{textAlign:"center"}}></Stack>
                    <Typography sx={{color:"var(--light-text-danger-text, #EF4444)",fontFamily:"inter",fontSize:"14px",fontWeight:700,lineHeight:"20px"}}>HEY THERE!</Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #1E293B)",fontFamily:"inter",fontSize:"12px",fontWeight:600,lineHeight:"16px"}}>Looks like your credit is about to get over</Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #1E293B)",fontFamily:"inter",fontSize:"12px",fontWeight:700,lineHeight:"16px"}}>Would you like to Make Payment?</Typography>
                        <Stack gap="12px" direction="row" sx={{textAlign:"center"}}>
                            <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#00C2FF"}} onClick={()=>{setIsPayment(false)}}>No</Button>
                            <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#9746FF"}} onClick={()=>redirectURL()}>Yes</Button>
                        </Stack>
                </Stack>   
                </Modal>} */}

            {isDeleteModalOpened && <Modal
                open={isDeleteModalOpened}
                onClose={()=>setIsDeleteModalOpened(false)}
                sx={{backdropFilter:"blur(5px)",display:"flex",justifyContent:"center",alignItems:"center"}}
            >
                <Stack gap="16px" sx={{width:"300px",textAlign:"center",p:"24px",borderRadius:"8px",background:"var(--pallate-blue-gray-100, #F1F5F9)",boxShadow:"0px 0px 36px 0px rgba(0, 0, 0, 0.25)"}}>
                    <Stack gap="8px" sx={{textAlign:"center"}}>
                        <Typography sx={{color:"var(--light-text-danger-text, #EF4444)",fontFamily:"inter",fontSize:"14px",fontWeight:700,lineHeight:"20px"}}>DELETE</Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #1E293B)",fontFamily:"inter",fontSize:"12px",fontWeight:600,lineHeight:"16px"}}>{selectedDeleteFile}</Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #1E293B)",fontFamily:"inter",fontSize:"12px",fontWeight:400,lineHeight:"16px"}}>If you remove the data source, you will lose all data visualizations associated with that data source.</Typography>
                        <Typography sx={{color:"var(--pallate-blue-gray-800, #1E293B)",fontFamily:"inter",fontSize:"12px",fontWeight:700,lineHeight:"16px"}}>Would you like to Remove?</Typography>
                    </Stack>
                    {
                        isDeleteLoading?
                            <Box sx={{textAlign:"center"}}>
                                <CircularProgress/>
                            </Box>
                        :
                        <Stack gap="12px" direction="row" sx={{textAlign:"center"}}>
                            <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#00C2FF"}} onClick={()=>{handleDeleteCancel()}}>No</Button>
                            <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#9746FF"}} onClick={()=>DeleteApi()}>Yes</Button>
                        </Stack>
                    }
                </Stack>
            </Modal>}
        </Box>
       {myProjects===false? <Box className="getstarted-mob">
           <Box className="getstarted-content-mob">
               <div className="threedotBox-dash">
                 <MenuThreedot username={username} url={savedurl} credits={credits} creditsColor={creditsColor}/>
               </div>     
           <img src={getstatedIcon} className="getstated-image"/>
               <div className="headingBox-mob">
               <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"48px",fontStyle:"normal",fontWeight:"800",lineHeight:"57px",background:"linear-gradient(115deg, #00C2FF 31.83%, #9746FF 84.85%)",backgroundClip:"text",WebkitTextFillColor:"transparent"}}>GET STARTED</Typography>
               <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"24px",fontStyle:"normal",fontWeight:"400",lineHeight:"32px",color:"var(--pallate-blue-gray-200, #E2E8F0)"}}>Transform your data into compelling visuals with our intuitive data visualization tool</Typography>
               </div>
            <div className="button-container-mob">
               <button className="create-project-mob">
               <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:"600",lineHeight:"24px",color:"var(--pallate-base-white, #FFF)"}} onClick={() => { navigate('/new-project') }}>Create New Project</Typography>
               </button>
               <button className="goto-myprojects" onClick={()=>setMyprojects(true)}>
               <Typography sx={{alignSelf:"stretch",fontFamily:"inter",fontSize:"16px",fontStyle:"normal",fontWeight:"600",lineHeight:"24px",color:"var(--pallate-base-white, #FFF)"}}>Go to My Projects</Typography> 
               </button>
               </div>
           </Box>
       </Box>:<Myprojects 
       details = {{
        savedurl:savedurl,
        username:username,
        credits:credits,
        creditsColor:creditsColor,
        projects :projects,
        setMyprojects:setMyprojects,
        saved_data:saved_data,
        handleChatOpen:handleChatOpen,
        Optionsopen:Optionsopen,
        anchorEl:anchorEl,
        handleOptionsClick:handleOptionsClick,
        handleOptionsClose:handleOptionsClose,
        handleRenameOption:handleRenameOption,
        handleDeleteOption:handleDeleteOption,
        handleDeleteCancel:handleDeleteCancel,
        openChart:openChart}}
       />}
        </>
       

    )
}

