import "../styles/Signup.css"
import { Box, Button, CircularProgress, Typography, useMediaQuery, Modal, Stack } from "@mui/material";
import pana from "../assets/icons/pana.svg"
import dnaviz from "../assets/icons/D&AViz_Without tagline-White-Lite.svg"
import user from "../assets/icons/user.svg"
import at from "../assets/icons/at.svg"
import lock from "../assets/icons/lock.svg"
import eyeSlash from "../assets/icons/eye-slash.svg"
import eye from "../assets/icons/eye.svg"
import forgotbg from "../assets/icons/forgot-bg.svg"
import signin from "../assets/icons/sign-in.svg"
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import { gtag_MeasurementID } from "../config";
import curveframe from "../assets/icons/svgviewer-png-output.png"

ReactGA.initialize(gtag_MeasurementID);
export function Signup(props) {
    const tab =  useMediaQuery('(min-width: 600px) and (max-width: 900px)');
    const api_base_url = props.api_base_url
    const [showPassword, setShowPassword] = useState(false);
    const [details,setDetails] = useState(
        {
            username:"",
            email:"",
            password:"",
            click_origin:""
        }
    );
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [errors,setErrors] = useState({})
    const [isSignedUp,setIsSignedUp] = useState(false)
    const [message, setMessage] = useState('')
    const [originClick, setOriginClick] = useState('')
    const navigate = useNavigate()
    
    useEffect(()=>{
        let click_origin = JSON.parse(window.sessionStorage.getItem('track_user_details'))
        setOriginClick(click_origin)
        console.log(click_origin)
    },[])

    const handlePasswordHide = (event) => {
        event.preventDefault()
        setShowPassword(!showPassword)
    }

    const handleCheckPassword = (value)=>{
        let password = value
        let dummy = {...errors}
        // console.log(password.length<8 , !/[A-Z]/.test(password) , !/[a-z]/.test(password) , !/\d/.test(password))
        if( password.length<8 || !/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/\d/.test(password) ){
            dummy.password = "A minimum 8 characters password contains a combination of uppercase and lowercase letter and number are required."
            // console.log("dummy----",dummy)
            setErrors(dummy)
        }
        else{
            if(dummy.password){
                // console.log("---coming for delete---")
                delete dummy.password
                setErrors(dummy)
            }
        }
        return dummy
    }

    function usernameValidation(username) {
        var pattern = /^[a-zA-Z0-9-_]+$/;
        return pattern.test(username);
      }
      function emailValidation(username) {
        var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return pattern.test(username);
      }
    const handleSubmit = async(e)=>{
        e.preventDefault()
        let error = handleCheckPassword(details.password)
        let details_keys = Object.keys(details)
        if(Object.values(details).length > 0){
            details_keys.map((key)=> {
                  delete error.usernameAlreadyExist
                  delete error.emailAlreadyExist
                  if (originClick){
                    if (originClick["click_origin"]==""){
                        details["click_origin"] = "website"
                    }
                    else{
                  details["click_origin"] = originClick["click_origin"]}}
                  else{
                    details["click_origin"]="direct"
                  }
                  !details["email"]?error["email"] = "Please enter your email here":!emailValidation(details.email)?error["email"] = "Please enter valid email address":delete error.email
                  !details["username"]?error["username"] = "Please enter your Username here":!usernameValidation(details.username)?error["username"] = "Invalid username. Please use only letters, numbers, underscores, and hyphens.": delete error.username
                  
                setErrors(error)
            })
        }
        console.log(error)
        if(Object.values(error).length === 0){
            console.log(details)
            let data = {...details,['password2']:details.password}
            setIsSignedUp(true)
            let res = await axios.post(api_base_url+'account/register/',data)
            console.log(res)
            setIsSignedUp(false)
            if(res.data.response === "Registration Successful!"){
                setDetails({
                    username:"",
                    email:"",
                    password:""
                })
                ReactGA.event("signup",{
                    category: "Signup",
                    action: "signup"
                });
                //navigate('/login')
                setMessage('We have emailed your Verification link ')
                error['usernameAlreadyExist'] = ""
                error['emailAlreadyExist'] = ""
                setErrors({error})
            }
            if (res.data.message=="User Name is already exist!"){
                error['usernameAlreadyExist'] = "Username already exist"
                setErrors(error)
            }
            else if (res.data.message=="email already exist!"){
                error['emailAlreadyExist'] = "The email is already exist / Invalid email"
                setErrors(error)
            }
            // if (res.data.name_error=="username already exist"){
            //     error['usernameAlreadyExist'] = "Username already exist"
            //     setErrors(error)
            // }
            // else if (res.data.username || res.data.email){
                
            //     if( res.data.username=="A user with that username already exists."){
            //         error['usernameAlreadyExist'] = "Username already exist"
            //         setErrors(error) 
            //     }
            // }
            // else{
            //     error['emailAlreadyExist'] = "The email is already exist / Invalid email"
            //         setErrors(error) 
            // }
        }
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(e); 
        }
      };
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    },[])

    const small = useMediaQuery("(max-width: 600px)");

    return (
        <Box className="loginContainer">

            { isSignedUp?  
                <Box sx={{textAlign:"center",width:"100%"}}>
                    <CircularProgress size={50}/>
                </Box>
            :
                <>
                    <Box className="RectangleFrame" sx={small&&{justifyContent:"unset",padding:"0px",backgroundImage:`url(${forgotbg})`,mb:"44px",backgroundPosition:"bottom",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
                        <Box sx={{ display: "flex", width: "100%", maxWidth: "635px", flexDirection: "column", alignItems: "center", gap: "36px",height:"100%",justifyContent:"center" }}>
                            { small && <Box>
                                <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "28px", textAlign: "center", width: "100%", color: "var(--pallate-blue-gray-50, #F8FAFC)" }}>
                                    Welcome to D&A VIZ
                                </Typography>
                                <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "28px", textAlign: "center", width: "100%", color: "var(--pallate-gray-50, #F9FAFB)" }}>
                                    Lets Get Started
                                </Typography>
                                { message && !small && <Box sx={{background: "var(--pallate-green-200, #BBF7D0)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                <Typography sx={{color: "var(--pallate-green-800, #166534)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{message}</Typography>
                                </Box>}
                            </Box>
                            }
                            <img src={pana} style={{ width: "287.723px", height: "290px" }} alt="pana" />
                            {!small && <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "34px" }}>
                                <img src={dnaviz} style={{ width: "147px", height: "40px" }} alt="dnaviz" />
                                <Typography sx={{ color: "#FFF", textAlign: "center", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "400", lineHeight: "28px" }}>Transform your raw data into insightful visualizations with our AI-powered data visualization feature.</Typography>
                            </Box>
                            }
                            { small && 
                                <Typography sx={{ color: "#FFF", textAlign: "center", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "28px" }}>Signup</Typography>
                            }
                            <Box className="featureList" sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", alignSelf: "stretch" }}>
                                <Box sx={{ display: "flex", padding: "12px", alignItems: "flex-start", gap: "8px", alignSelf: "stretch", background: "rgba(103, 232, 249, 0.20)" }}>

                                    <Typography sx={{ color: "#FFF", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px" }}>
                                        <span style={{ color: "#FFF", marginRight: "3px", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "700", lineHeight: "20px" }}>
                                            Instant Insights:
                                        </span>
                                        Gain a deeper understanding of your data through interactive and dynamic visuals, making complex data easy to interpret at a glance.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", padding: "12px", alignItems: "flex-start", gap: "8px", alignSelf: "stretch", background: "rgba(147, 51, 234, 0.20)" }}>

                                    <Typography sx={{ color: "#FFF", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px" }}>
                                        <span style={{ color: "#FFF", marginRight: "3px", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "700", lineHeight: "20px" }}>
                                            Effortless Analysis:
                                        </span>
                                        Save time and resources by automating the process of creating charts and graphs, allowing you to focus on making data-driven decisions.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", padding: "12px", alignItems: "flex-start", gap: "8px", alignSelf: "stretch", background: "rgba(103, 232, 249, 0.20)" }}>
                                    <Typography sx={{ color: "#FFF", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px" }}>
                                        <span style={{ color: "#FFF", marginRight: "3px", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "700", lineHeight: "20px" }}>
                                            Predictive Analytics:
                                        </span>
                                        Leverage AI algorithms to uncover patterns and trends, helping you forecast future outcomes and make proactive choices.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", padding: "12px", alignItems: "flex-start", gap: "8px", alignSelf: "stretch", background: "rgba(147, 51, 234, 0.20)" }}>
                                    <Typography sx={{ color: "#FFF", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px" }}>
                                        <span style={{ color: "#FFF", marginRight: "3px", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "700", lineHeight: "20px" }}>
                                            Customization:
                                        </span>
                                        Tailor your visuals to your specific needs with customizable templates and design options, ensuring your data speaks your language.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", padding: "12px", alignItems: "flex-start", gap: "8px", alignSelf: "stretch", background: "rgba(103, 232, 249, 0.20)" }}>
                                    <Typography sx={{ color: "#FFF", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px" }}>
                                        <span style={{ color: "#FFF", marginRight: "3px", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "700", lineHeight: "20px" }}>
                                            Collaboration:
                                        </span>
                                        Share visualizations effortlessly with your team, fostering collaboration and better-informed decision-making across your organization.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="loginFrame">
                        <Box sx={{height:small? "fit-content":"100vh",width:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                           <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Box className="loginContents" sx={{ width: "100%" }}>
                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                    { !small && <>
                                        <Typography sx={{ fontFamily: "inter", fontSize: "30px", fontStyle: "normal", fontWeight: "700", lineHeight: "36px", textAlign: "center", width: "100%", color: "var(--pallate-blue-gray-50, #F8FAFC)" }}>
                                            Welcome to D&A VIZ
                                        </Typography>
                                        <Typography sx={{ fontFamily: "inter", fontSize: "30px", fontStyle: "normal", fontWeight: "700", lineHeight: "36px", textAlign: "center", width: "100%", color: "var(--pallate-gray-50, #F9FAFB)" }}>
                                            Lets Get Started
                                        </Typography></>
                                        
                                    }
                                </Box>
                                <form style={{width:"100%"}} onKeyDown={(e)=>handleKeyPress(e)}   onSubmit={(e)=>handleSubmit(e)}>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: small ? "32px" : "48px", alignSelf: "stretch" }} >
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap:!details.password && errors.password?"16px" : "24px", alignSelf: "stretch" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                            {errors.username&&!details.username ?<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.username}</Typography></Box>:errors.username=="Invalid username. Please use only letters, numbers, underscores, and hyphens."&&details.username&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.username}</Typography>
                                            </Box>}
                                            {errors.usernameAlreadyExist&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.usernameAlreadyExist}</Typography></Box>}
                                            { message && <Box sx={{background: "var(--pallate-green-200, #BBF7D0)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                        <Typography sx={{color: "var(--pallate-green-800, #166534)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{message}</Typography>
                                        </Box>}
                                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left", alignSelf: "stretch" }}>
                                                    User Name <Typography component="span" sx={{color:"red"}}>*</Typography>
                                                </Typography>
                                                <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <img src={user} alt="user" />
                                                    <input id="username" name="username" value={details.username} onChange={(e)=>{setDetails({...details, [e.target.name] : e.target.value})}} placeholder="Enter User Name" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} />
                                                </Box>                                   
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                            {errors.email&&!details.email ?<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.email}</Typography></Box>:errors.email=="Please enter valid email address"&&details.email&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.email}</Typography>
                                            </Box>}
                                            {errors.emailAlreadyExist&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.emailAlreadyExist}</Typography></Box>}
                                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                                    Email ID <Typography component="span" sx={{color:"red"}}>*</Typography>
                                                </Typography>
                                                <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <img src={at} alt="at-icon" />
                                                    <input id="email"  name="email" value={details.email} onChange={(e)=>{setDetails({...details,[e.target.name]:e.target.value})}} placeholder="Enter Your Email ID" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} />
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                            {errors.password && <Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.password}</Typography>
                                            </Box>}
                                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                                    Password <Typography component="span" sx={{color:"red"}}>*</Typography>
                                                </Typography>

                                                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}> <img src={lock} alt="lock" />
                                                        <input onBlur={(e)=>handleCheckPassword(e.target.value)} id="password"  name="password" type={showPassword?"text":"password"} value={details.password} placeholder="Enter Password" onChange={(e)=>setDetails({...details,[e.target.name]: e.target.value})} style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} /></Box>
                                                    <button style={{ border: "none", backgroundColor: "transparent", cursor: "pointer" }} onClick={(e) => handlePasswordHide(e)}> {!showPassword?<img src={eyeSlash} style={{ display: "flex" }} alt="eye" />:<img src={eye}  style={{ display: "flex" }}  alt="eye-icon" />}</button>
                                                </Box>
                                            </Box>
                                            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                <input type="checkbox"  className="checkBox" checked={isTermsAccepted} onChange={(e)=>setIsTermsAccepted(e.target.checked)} />
                                                <Typography sx={{ color: "var(--pallate-purple-50, #FAF5FF)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px" }}>Accept Terms & Condition</Typography>
                                            </Box> */}
                                        </Box>
                                        <button id="signup-btn" disabled={details.email && details.username && details.password?false:true}  
                                            type="submit"  style={{ display: "flex", padding: "12px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", 
                                            background:!details.email ||!details.username|| !details.password? "#475569" : "#9746FF" , cursor: details.email && details.username && details.password?"pointer":"not-allowed",border:"none"}}>
                                            <Typography  style={{ color: "#FFF", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: 'none', }}>Create account</Typography>
                                            <img src={signin} style={{ display: "flex", width: "24px", height: "24px", padding: "3.278px 3.25px 3.248px 3.25px", justifyContent: "center", alignItems: "center" }} alt="signin" />
                                        </button>
                                    </Box>
                                </form>

                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <Box sx={{ display: "flex", width: "100%", maxWidth: small?"100%": "603px", padding: small ? "20px" : "32px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", background: "var(--pallate-blue-gray-900, #0F172A)" }}>
                                <Typography sx={{ color: "var(--pallate-blue-gray-200, #E2E8F0)", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px" }}>Already have an account?</Typography>
                                <Typography onClick={() => { navigate('/login') }} sx={{ color: "#00C2FF", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px", '&:hover': { cursor: "pointer" } }}>Login</Typography>
                            </Box>
                        </Box> 
                        </Box>
                        
                    </Box>
                </>
            }
        </Box>
    )
}