import { Box, CircularProgress, Typography, useMediaQuery, Modal, Stack, Button } from "@mui/material";
import "../styles/Login.css"
import pana from "../assets/icons/computer_pana.svg"
import user from "../assets/icons/user.svg"
import lock from "../assets/icons/lock.svg"
import eyeSlash from "../assets/icons/eye-slash.svg"
import eye from "../assets/icons/eye.svg"
import login from "../assets/icons/sign-in.svg"
import frame from "../assets/landing/D&AViz_Without tagline-Color-Light Lite.svg"
import forgotbg from "../assets/icons/forgot-bg.svg"
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import { gtag_MeasurementID } from "../config";
ReactGA.initialize(gtag_MeasurementID);

export function Login(props) {
    const tab =  useMediaQuery('(min-width: 600px) and (max-width: 900px)');
    const api_base_url = props.api_base_url
    const [showPassword, setShowPassword] = useState(false);
    const [afterResponse,setafterResponse] = useState(false)
    const [details,setDetails] = useState(
    {
        username:"",
        password:""
    }
    );
    const [errors,setErrors] = useState({})
    const [isLogined,setIsLogined] = useState(false)
    const queryParameters = new URLSearchParams(window.location.search)
    const [isregistry, setIRegistry] = useState(false)
    const [loginerror, setLoginError] = useState('')
    const [verifymessage, setVerifyMessage] = useState('')

    let registry = queryParameters.get('Registration_success')
    let registered = queryParameters.get('Already_registed')
    let notregistry = queryParameters.get('Not_Registered')
    useEffect(()=>{
        if (registry){
        console.log(registry)
        console.log("we are here")
        setIRegistry(true)
        setVerifyMessage('Congratulations, your account have been successfully verified')
        }
        else if (notregistry){
            setVerifyMessage('')
            setLoginError('Your email id does not exist / Verfication failed')   
        }
        else if (registered){
            setVerifyMessage('Congratulations, your account have been successfully verified')
        }

    },[])
    
    const navigate = useNavigate()

    const handleRegistrymodelClose = () => {
        setIRegistry(false)
    }

    const handlePasswordHide = (event) =>{
        event.preventDefault()
        setShowPassword(!showPassword)
    }
    const handleSubmit = async(e)=>{
        ReactGA.event({
            category:"Btn_click",
            action:"Login_btn"
        })
        e.preventDefault()
        let error = {}
        let details_keys = Object.keys(details)
        if(Object.values(details).length === 0){
            // setError("Invalid Creditinals")
        }
        // console.log(error)
        if(Object.values(details).length > 0){
            details_keys.map((key)=> {
            
                if (!details["username"]){
                   error["username"] = "Please enter user name / email id here "
               }
               if(!details["password"]){
                   error["password"] = "Please enter password here"
   
               }})
               setErrors(error)
        }
        if(Object.values(error).length === 0){
            try{
                setIsLogined(true)
                let res = await axios.post(api_base_url+'account/login/',details).catch((err)=>{
                        console.log(res.data.data)
                        error["password2"]="Your username or password is incorrect"
                        setVerifyMessage('')
                        setErrors(error)
                    setafterResponse(true)})
                setIsLogined(false)
                console.log(res)
                if(res.data.token){
                    let user_details = res.data.user_details
                    window.sessionStorage.setItem('userdetails',JSON.stringify({"username":user_details.username,'email':user_details.email}))
                    ReactGA.event("login",{
                        category: "Login",
                        action: "login",
                        user_name: user_details.username
                    });
                    navigate('/dashboard')
                }
                if (res.data.error=="You have not verfied you email"){
                    setLoginError("Please verify your email before login")
                }
                else{
                    //error["password"]="Your username or password is incorrect"
                    setVerifyMessage('')
                    setLoginError("Your username or password is incorrect")
                }
            }
            catch(error){
                // setErrors("Your username or password is incorrect")
            }
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(e); 
        }
      };
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    },[])

    const small = useMediaQuery("(max-width: 600px)");

    return (
        <Box className="loginContainer">
            {/* {isregistry && <Modal
                open={registry}
                sx={{backdropFilter:"blur(5px)",display:"flex",justifyContent:"center",alignItems:"center"}}
                onClose={()=>handleRegistrymodelClose()}
            >
                <Stack gap="16px" sx={{width:"300px",textAlign:"center",p:"24px",borderRadius:"8px",background:"var(--pallate-blue-gray-100, #F1F5F9)",boxShadow:"0px 0px 36px 0px rgba(0, 0, 0, 0.25)"}}>
                    <Stack gap="8px" sx={{textAlign:"center"}}></Stack>
                    <Typography sx={{color:"var(--light-text-danger-text, #EF4444)",fontFamily:"inter",fontSize:"14px",fontWeight:700,lineHeight:"20px"}}>Awesome!</Typography>
                    <Typography sx={{color:"var(--pallate-blue-gray-800, #1E293B)",fontFamily:"inter",fontSize:"12px",fontWeight:600,lineHeight:"16px"}}>Your email verification was successful.</Typography>
                    <Button fullWidth variant="contained" sx={{p:"8px 12px",borderRadius:"4px",bgcolor:"#00C2FF"}} onClick={()=>{handleRegistrymodelClose()}}>Continue</Button>
            </Stack>
            </Modal>
            } */}
            { isLogined ?  
                <Box sx={{textAlign:"center",width:"100%"}}>
                    <CircularProgress size={50}/>
                </Box>
            :
            <>
                <Box className="Rectangleframe"  sx={small&&{justifyContent:"unset",padding:"0px",backgroundImage:`url(${forgotbg})`,mb:"44px",backgroundPosition:"bottom",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
                    <Typography sx={{ color: small ? "#F8FAFC" : "var(--pallate-gray-300, #D1D5DB)", marginBottom: "30px", maxWidth: small ? "320px" : "530px",width:"100%", textAlign: "center", fontFamily: "inter", fontSize: small ? "20px" : "48px", fontStyle: "normal", fontWeight: "400", lineHeight: small ? "28px" : "57px" }}>
                        <span style={{ display:"block", color: "var(--pallate-blue-gray-50, #F8FAFC)", marginRight: "20px", fontFamily: "inter", fontWeight: "800" }}>
                            Welcome back!
                        </span>
                        Log in to your account
                    </Typography>
                    <img src={pana} style={{ width: "100%", maxWidth: "min(90vw, 604px)", flexShrink: "1" }} alt="pana" />
                    {small &&
                        <Typography sx={{ height: "32px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "center", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "700", lineHeight: "28px" }}>Login</Typography>
                    }
                </Box>
                <Box className="container-right">
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height:small? "fit-content":"100vh"}}>
                        <Box className="login-logo" sx={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "10px" }}>
                            <img src={frame} style={{width:"82px",height: "30px", flexShrink: "0", marginTop: "36px", marginRight: "32px",cursor:"pointer" }} alt="frame" onClick={()=>navigate("/")}/>
                        </Box>
                        <Box sx={{ display: "inline-flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.10)" }}>

                            <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                <Box sx={{ display: "flex", width: "100%", maxWidth: "603px", padding:small?"0px 16px":"32px", flexDirection: "column", alignItems: "center", gap:errors?"38px":"56px", background: "var(--pallate-blue-gray-700, #334155)" }}>
                                    {!small &&
                                        <Typography sx={{ height: "32px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "center", fontFamily: "inter", fontSize: "30px", fontStyle: "normal", fontWeight: "700", lineHeight: "36px" }}>Login</Typography>
                                    }
                                    {loginerror!="" && !small&& <Box sx={{background: "var(--pallate-red-200, #FECACA)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                    <Typography sx={{color: "var(--pallate-red-800, #991B1B)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{loginerror}</Typography>
                                    </Box>}
                                    {verifymessage!="" && <Box sx={{background: "var(--pallate-green-200, #BBF7D0)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                <Typography sx={{color: "var(--pallate-green-800, #166534)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{verifymessage}</Typography>
                                </Box>}
                                     {errors.password2 && details.password&& !small&& <Box sx={{background: "var(--pallate-red-200, #FECACA)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                    <Typography sx={{color: "var(--pallate-red-800, #991B1B)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{errors.password2}</Typography>
                                    </Box>}
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: small?"32px":"48px", alignSelf: "stretch" }}>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-star", gap: small?"16px":"24px", alignSelf: "stretch" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                            {errors.username && !details.username&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.username}</Typography>
                                            </Box>}
                                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                                    User Name / Mail ID <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)"}}>*</Typography>
                                                </Typography>
                                                <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <img src={user} alt="user" />
                                                    <input id="username" name="username" value={details.username} onKeyDown={(e)=>handleKeyPress(e)}  onChange={(e)=>{setDetails({...details, [e.target.name] : e.target.value})}} placeholder="Enter your user name / mail id" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} />
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                            {errors.password && !details.password&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.password}</Typography>
                                            </Box>}
                                            {errors.password2 && details.password&&small&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{errors.password2}</Typography>
                                            </Box>}
                                            {loginerror!="" &&small&&<Box sx={{ order: 1 }}>
                                            <Typography sx={{ color: "#EF4444" }}>{loginerror}</Typography>
                                            </Box>}
                                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                                    Password <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)"}}>*</Typography>
                                                </Typography>

                                                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}> <img src={lock} alt="lock" />
                                                        <input id="password" name="password" type={showPassword?"text":"password"} value={details.password} onKeyDown={(e)=>handleKeyPress(e)}  placeholder="Enter Password" onChange={(e)=>setDetails({...details,[e.target.name]:e.target.value})} style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} /></Box>
                                                <button style={{ border: "none", backgroundColor: "transparent", cursor: "pointer" }} onClick={(e) => handlePasswordHide(e)}> {!showPassword?<img src={eyeSlash} style={{ display: "flex" }} alt="eye" />:<img src={eye}  style={{ display: "flex" }}  alt="eye-icon" />}</button>
                                
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", alignSelf: "stretch" }}>
                                            <button id="login-btn" disabled={details.username && details.password?false:true} onClick={(e)=>handleSubmit(e)} style={{ display: "flex", flexDirection: "row", color: "#FFF", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", 
                                                background: !details.username || !details.password?"#475569" :"#9746FF", padding: "12px", justifyContent: "center", alignItems: "center", alignSelf: "stretch", borderRadius: "4px", border: 'none', cursor: !details.username || !details.password?"not-allowed": "pointer" }}>Login
                                                <img src={login} style={{ display: "flex", marginLeft: "8px", width: "16px", height: "24px", padding: "3.278px 3.25px 3.248px 3.25px", justifyContent: "center", alignItems: "center" }} alt="login" />
                                            </button>
                                            <Box sx={{ display: "flex", padding: "12px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px" }}>
                                                <button onClick={() => { navigate('/forgotpassword') }} style={{ color: "#00C2FF", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", backgroundColor: "transparent", border: "none", cursor: "pointer" }}>Forgot Password?</button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                <Box sx={{ display: "flex", width: "100%", maxWidth: small?"100%": "603px", padding: small?"20px":"32px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", background: "var(--pallate-blue-gray-900, #0F172A)" }}>
                                    <Typography sx={{ color: "var(--pallate-blue-gray-200, #E2E8F0)", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px" }}>Don't have an account?</Typography>
                                    <Typography onClick={() => { navigate('/signup') }} sx={{ color: "#00C2FF", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px", '&:hover': { cursor: "pointer" } }}>Signup</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            }
        </Box>
    )

}