import { Route, Routes } from "react-router-dom";
import {LandingPage} from "./pages/landing-page";
import { Dashboard } from "./pages/Dashboard";
import { CreateProject } from "./pages/create-project";
import { Chat } from "./pages/chat-page";
import { Signup } from "./pages/Signup";
import { Login } from "./pages/Login"
import { ForgotPassword } from "./pages/forgot-password";
import { ResetPassword } from "./pages/Reset-password";
import { UpdatePassword } from "./pages/update-password";
import { Loading } from "./pages/Loading";
import { Payment } from "./pages/payment";
import { Payment_Success } from "./pages/payment-sucess.js";
import { Myprojects } from "./pages/myprojects";


function App() {
  const api_base_url = 'https://controllayer-prod.arivu-iq.com/'
  // const api_base_url = "http://127.0.0.1:8001/"
  // const save_function='http://127.0.0.1:8000/'
  const save_function='	https://drf-il-prod.arivu-iq.com/'
  //const api_base_url = 'https://ildemo.nlugpt.io/'
  //const save_function= 'https://ildemo.nlugpt.io/'
  return (
    <div className="App">
      <Routes>
        <Route eact path="*" element={<LandingPage api_base_url={api_base_url}/>}></Route>
        {/* <Route eact path="/signup" element={<Signup api_base_url={api_base_url}/>}></Route>
        <Route eact path="/myprojects" element={<Myprojects api_base_url={api_base_url}/>}></Route>
        <Route eact path="/login" element={<Login api_base_url={api_base_url}/>}></Route>
        <Route eact path="/forgotpassword" element={<ForgotPassword api_base_url={api_base_url}/>}></Route>
        <Route eact path="/reset-password" element={<ResetPassword api_base_url={api_base_url}/>}></Route>
        <Route eact path="/update-password" element={<UpdatePassword />}></Route>
        <Route path="payment" element={<Payment api_base_url={api_base_url} savedata={save_function}/>} />
        <Route path="payment_sucess" element={<Payment_Success api_base_url={api_base_url}/>} />
        <Route path="dashboard" element={<Dashboard api_base_url={api_base_url} savedata={save_function}/>} />
        <Route path="new-project" element={<CreateProject api_base_url={api_base_url} savedata={save_function}/>} />
        <Route path="new-project/:projectname" element={<CreateProject api_base_url={api_base_url} savedata={save_function}/>} />
        <Route path="chat" element={<Chat api_base_url={api_base_url} savedata={save_function}/>} />
        <Route path="loader" element={<Loading />} /> */}
      </Routes>
    </div>
  );
}

export default App;
